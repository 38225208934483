import React, { useContext, useEffect, useState } from "react";
import deshvideshimmigration from "../../Image/deshvideshimmigration.png";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import "./BookAppointment.css";
import { Colorcontext } from "../../Component/context/ColorContext";
import BackPageBtn from "./BackPageBtn";
import HomeServices from "../../Services/HomeServices";
import Swal from "sweetalert2";
import CustomLoader from "../../loader/CustomLoader";

const UrgentTermsCondition = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    const [paymentData, setPaymentData] = useState({
      customer_id: "",
      price: "",
      name: "",
      success_url: "",
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const colordata = useContext(Colorcontext);
    useEffect(() => {
      colordata.setcolor(true);
      return () => {
        colordata.setcolor(false);
      };
    });
  
    const handleCheckboxChange = () => {
      // Update the state when the checkbox is clicked
      setIsChecked(!isChecked);
    };
  
    // converting price and 
    const strPrice = location?.state?.price;
    const numPrice = parseInt(strPrice.slice(1), 10);
  
    const mobile = location?.state?.mobileNumber;
    let mobNumber = parseInt(mobile, 10);
    // console.log("mxncbj7645674gbw", numPrice);
  
    const onSubmit = async (e) => {
      e.preventDefault();
      console.log("cus_idservicetype", location?.state);
  
      if (!isChecked) {
        return toast.error("Please, Check the Terms & Condition");
      } else {
        // console.log("hfg7465uyf267", location?.state);
        let data = {
          price: numPrice,
          name: location?.state?.name,
          booking_serviceType_id: location?.state?.booking_serviceType_id,
          date: location?.state?.date,
          timing_id: location?.state?.timing_id,
          mobileNumber: mobNumber,
          email: location?.state?.email,
          firstName: location?.state?.firstName,
          lastName: location?.state?.lastName,
          note: location?.state?.note,
          country: location?.state?.country,
          mobileCode: location?.state?.mobileCode,
          isUrgent: location?.state?.isUrgent
        };
        setIsLoading(true)
        try {
          const res = await HomeServices.makePayment(data);
          if (res && res?.status) {
            // window.open(res?.data?.url, "_blank");
            window.location.href = res?.data?.url;
          }
        } catch (error) {
          console.error("Error making payment:", error);
          toast.error("Error making payment. Please try again.");
        } finally {
          setIsLoading(false)
        }
  
      }
    };
  
  
    useEffect(() => {
      const interval = setInterval(() => {
        setIsVisible((prevVisible) => !prevVisible);
      }, 1000);
  
      return () => clearInterval(interval);
    }, []);
  return (
    <>
      <section className="bookAppoSection">
        <div className="cust_container">
          <p className="bookAppHead">Our Terms And Condition</p>
          <div className="immiAllDivsMain">
            <div className="lghdiv">
              <figure Link to="/" className="logo_hd_mn">
                <img src={deshvideshimmigration} alt="..." />
                <p className="deshtxt">DeshVidesh Immigration</p>
              </figure>
            </div>
          </div>

          <div>
            <h4>Initial Consultation Agreement</h4>

            <div className="mt-4">
              <span style={{ fontWeight: "bold" }}>
                1. Purpose of Consultation
              </span>{" "}
              <br />{" "}
              <p className="mx-3 mt-2">
                The purpose of an Initial consultation is to provide the
                consultant with sufficient information to allow for a full
                analysis and comprehensive advice on immigration options,
                including estimated timeframes, legal fees, and costs.
              </p>
            </div>

            <div className="mt-4">
              <span style={{ fontWeight: "bold" }}>2. Fees</span> <br />
              <p className="mx-3 mt-2">
                The Client is obliged to pay the full fee in CAD.
              </p>
            </div>

            <div className="mt-4">
              <span style={{ fontWeight: "bold" }}>3. Limited Scope</span>{" "}
              <br />{" "}
              <p className="mx-3 mt-2">
                No Consultant-Client relationship is intended to be established
                by the consultation agreement. At the conclusion of initial
                consultation there is no obligation for you to retain us, nor do
                we have an obligation to provide any services to you, unless
                further engagement or full retainer agreement is signed.
              </p>
            </div>

            <div className="mt-4">
              <span style={{ fontWeight: "bold" }}>
                4. Method of Consultation
              </span>{" "}
              <br />{" "}
              <p className="mx-3 mt-2">
                RCIC shall provide consultation to the client in person or by
                phone. The consultation will last for such period of time as
                necessary for RCIC to perform the services under this agreement,
                but in any event, shall not be longer than 20 minutes.
              </p>
            </div>

            <div className="mt-4">
              <span style={{ fontWeight: "bold" }}>5. Confidentiality</span>{" "}
              <br />{" "}
              <p className="mx-3 mt-2">
                Any information or documentation reviewed by the RCIC in the
                course of the consultation, will not be divulged to any third
                party, other than RCIC’s agents and employees, without any prior
                consent of the client except as demanded by the law.
              </p>
            </div>

            <div className="mt-4">
              <span style={{ fontWeight: "bold" }}>6. Validation</span> <br />{" "}
              <p className="mx-3 mt-2">
                This Agreement shall be governed by the laws in effect in the
                Province of Ontario and the federal laws of Canada applicable
                therein. Please be advised that Sujeet Dahiya is a member in
                good standing of the College of Immigration and Citizenship
                Consultants (CICC), and as such, is bound by its By-law, Code of
                Professional Ethics, and Regulations.
              </p>
            </div>

            <div className="mt-4">
              <span style={{ fontWeight: "bold" }}>7. Acknowledgment</span>{" "}
              <br />
              <p className="mx-3 mt-2">
                By clicking below, you acknowledge that you have read the above
                terms and conditions and that you agree to be bound by all terms
                and conditions of this agreement
              </p>
            </div>
            <div className="form-group mt-4">
              <input
                type="checkbox"
                className="inputChecked"
                id="Check"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="LoginRem mx-2" style={{ fontWeight: "500" }}>
                I agree to the{" "}
                <span style={{ color: "#3366CC", fontWeight: "bold" }}>
                  Terms & Condition
                </span>
              </span>
            </div>

            <div className="mt-4">
              <button type="button" className="joinWtListBtn" onClick={onSubmit}>
                Submit
              </button>
            </div>
            <div> <p style={{ fontWeight: "bold" }} > <span style={{ color: "red", fontSize: "25px", }}>*</span>A Pop-Up window will show you the payment gateway. Please allow it to proceed with your Payment and booking confirmation.</p>
            </div>

            <BackPageBtn backLink="/joinwaitlist" />
          </div>
        </div>
      </section>
      <CustomLoader loading={isLoading} />
    </>
  )
}

export default UrgentTermsCondition