import { React, useCallback, useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import "./BookAppointment.css";
import BookAppImmi from "../../Component/BookAppointment/BookAppImmi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import SelectTimeZone from "../../Component/Modal/SelectTimeZone";
import BackPageBtn from "./BackPageBtn";
import toast from "react-hot-toast";
import HomeServices from "../../Services/HomeServices";
import { Colorcontext } from "../../Component/context/ColorContext";
import { validTimezones } from "./TimeZone";
const PreferDateTime = () => {
  const colordata = useContext(Colorcontext);
  
  let newDate = new Date();
  const formData = JSON.parse(localStorage.getItem("formData"));
  const [TorontoTimeZone, setTorontoTimeZone] = useState("America/Toronto");
  const currentDateTimeToronto = moment.tz(TorontoTimeZone);
  const defaultDate = currentDateTimeToronto.format("ddd, MMM D, YYYY");

  const dateTimeField = {
    date: moment(newDate).tz(TorontoTimeZone).format("YYYY-MM-DD"),
    time: "",
    value: formData,
    // value: location?.state,
  };

  const [viewBlockdate, setViewBlockdate] = useState([]);
  const [allTime, setAllTime] = useState([]);
  const [dateTime, setDateTime] = useState(dateTimeField);
  const [modalA, setModalA] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [onChangeDate, setOnChangeDate] = useState(defaultDate);

  const location = useLocation();

  const navigate = useNavigate();

  console.log(validTimezones, "preferdateTime");
  console.log(TorontoTimeZone, "TorontoTimeZone");

  const tileClassName = ({ date, view }) => {
    // Add a custom class to the active (selected) date
    if (
      selectedDate &&
      date.getDate() === selectedDate.getDate() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getFullYear() === selectedDate.getFullYear()
    ) {
      return "active-date";
    }
    // Add a custom class to the current date (optional)
    if (
      view === "month" &&
      date.getDate() === new Date().getDate() &&
      date.getMonth() === new Date().getMonth() &&
      date.getFullYear() === new Date().getFullYear()
    ) {
      return "current-date";
    }
    return null;
  };

  useEffect(() => {
    GetAllDate(moment().tz(TorontoTimeZone).format("YYYY-MM-DD"));
    fetchViewBlockDate();
  }, [TorontoTimeZone]);

  const GetAllDate = async (date) => {
    let data = {
      booking_serviceType_id: location?.state?._id,
      date: date,
      timezone: TorontoTimeZone,
    };
    const res = await HomeServices.availableTimeByDate(data);
    if (res && res.status) {
      setAllTime(res.data);
    }
  };

  const fetchViewBlockDate = async () => {
    const res = await HomeServices.viewBlockDate();
    if (res && res?.status) {
      setViewBlockdate(res?.data);
    }
  };

  const blockDate = (date) => {
    const formatdate = moment(date).tz(TorontoTimeZone).format("YYYY-MM-DD");
    const check = viewBlockdate.find((item) => item?.block_date === formatdate);
    if (check) {
      return true;
    } else {
      return false;
    }
  };

  const handleDateChange = async (date) => {
    const chDate = moment(date).format("ddd, MMM D, YYYY");
    setOnChangeDate(chDate);

    GetAllDate(moment(date).format("YYYY-MM-DD"));
    setDateTime({
      ...dateTime,
      date: date,
      time: "",
    });

    setSelectedDate(date);
  };

  const tileDisabled = ({ date }) => {
    let todayDay = moment(newDate).tz(TorontoTimeZone).format("YYYYMMDD");
    let selectedDate = moment(date).format("YYYYMMDD");

    const currentDate = new Date(
      moment(newDate).tz(TorontoTimeZone).format("YYYY-MM-DD")
    );
    currentDate.setHours(0, 0, 0, 0);
    const isPastDate = todayDay > selectedDate;

    const fourteenDaysAhead = new Date();
    fourteenDaysAhead.setDate(currentDate.getDate() + 14);
    fourteenDaysAhead.setHours(0, 0, 0, 0);
    const isDateBeyondFourteenDays = date > fourteenDaysAhead;

    const isSunday = date.getDay() === 0;

    const isBlocked = blockDate(date);

    return isPastDate || isDateBeyondFourteenDays || isSunday || isBlocked;
  };

  const onSUbmit = () => {
    if (dateTime.date && dateTime.time) {
      if (typeof dateTime.time === "string") {
        const fullDateTime = new Date(dateTime.date);
        const [hours, minutes] = dateTime.time.split(":");
        fullDateTime.setHours(parseInt(hours), parseInt(minutes));

        navigate("/joinwaitlist", { state: dateTime });
      } else {
        toast.error("Invalid time format");
      }
    } else {
      toast.error("Please Select the Date and Time");
    }
  };

  const isBlocked = (item) =>
    item.isBooked || item.isTimeOver || item.isBlockedTime;

  useEffect(() => {
    colordata.setcolor(true);

    return () => {
      colordata.setcolor(false);
    };
  }, []);
  return (
    <>
      <section className="predateSection">
        <div className="cust_container">
          <BookAppImmi />
          <div className="row">
            <div className="col-12">
              <p className="slYuHead">Select your preferred date and time</p>

              {/* calender */}
              <div className="selectClndrDiv">
                <Calendar
                  onChange={handleDateChange}
                  value={dateTime?.date}
                  tileDisabled={tileDisabled}
                  tileClassName={tileClassName}
                />
              </div>

              {/*available on  */}
              <div className="aviYouDiv">
                <p className="aviMonHead">Available on {onChangeDate}</p>
                <div
                  className="d-flex align-items-center"
                  style={{ flexWrap: "wrap" }}
                >
                  <p className="youCnSchl">
                    You can schedule an appointment between 2 hour and 14 days
                    ahead of time. Time zone:{" "}
                    {/* <span>
                      EASTERN TIME - TORONTO (GMT-04:00)
                    </span> */}
                  </p>
                  <form>
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={TorontoTimeZone}
                        onChange={(e) => setTorontoTimeZone(e.target.value)}
                      >
                        {validTimezones?.map((timezone, index) => (
                          <option key={index} value={timezone}>
                            {timezone}
                          </option>
                        ))}
                      </select>
                    </div>
                  </form>
                </div>
              </div>

              {/* time list */}
              {moment(onChangeDate).day() !== 0 && (
                <div className="timeScheduleDiv">
                  <div className="sevTimeAmDiv" style={{ cursor: "pointer" }}>
                    {allTime.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`sevTimeAm ${isBlocked(item)
                              ? "sevTimeAmSelectColor"
                              : item.selected
                                ? "sevTimeAmSelectColor sevTimeAmColor"
                                : "sevTimeAmColor"
                            }`}
                          style={{
                            borderColor: item.selected ? "green" : null,
                          }}
                          onClick={() => {
                            if (!isBlocked(item)) {
                              setAllTime((state) => {
                                let prevIndex = state.findIndex(
                                  (it) => it.selected
                                );
                                if (prevIndex >= 0) {
                                  state[prevIndex].selected = false;
                                }
                                state[index].selected = true;

                                return JSON.parse(JSON.stringify(state));
                              });
                              setDateTime({
                                ...dateTime,
                                time: item._id,
                              });
                            }
                          }}
                        >
                          {item.selected ? (
                            <i class="fa-solid fa-check"></i>
                          ) : null}
                          <span style={{}} className={"sevTimeAmSelectColor"}>
                            {" "}
                            <span
                              style={{
                                margin: "5px",
                                padding: "5px",
                                cursor: "pointer",
                                color:
                                  !isBlocked(item) && !item.selected
                                    ? "#fff"
                                    : null,
                              }}
                            >
                              {item?.time} {!isBlocked(item) ? "":<span> ( booked <i class="fa-solid fa-check"></i>)</span>}
                            </span>
                          </span>{" "}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="serMDiv">
                <p className="sevMorHead">
                  Don't see your preferred date or time?
                </p>
                <div>
                  <button
                    onClick={() =>
                      navigate("/joinwaitlist", { state: dateTime })
                    }
                    style={{ border: "none" }}
                    className="joinWaitLink"
                  >
                    Join waitlist
                  </button>
                  <button
                    onClick={onSUbmit}
                    style={{ border: "none" }}
                    className="joinWaitLink m-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <BackPageBtn backLink="/bookappointment" />
        </div>
      </section>

      {modalA && <SelectTimeZone closeTimeZoneModal={setModalA} />}
    </>
  );
};

export default PreferDateTime;
