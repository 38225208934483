import React, { useContext, useEffect, useState } from "react";
import "./BookAppointment.css";
import BookAppImmi from "../../Component/BookAppointment/BookAppImmi";
import { Colorcontext } from "../../Component/context/ColorContext";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
    Link,
    Navigate,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import HowcanMeetForm from "./HowcanMeetForm";
import BackPageBtn from "./BackPageBtn";
import toast from "react-hot-toast";
import HomeServices from "../../Services/HomeServices";
import countryCode from "../../Country_Code";
import countryName from "../../Country_Name";

import { format } from "date-fns";
import moment from "moment-timezone";
import Swal from "sweetalert2";

const contactFeild = {
    mobileNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    note: "",
    countryName: "",
    countryCode: "+1",
};

const waitListField = {
    startDate: "",
    endDate: "",
    timeId: "",
};

const UrgentJoinList = () => {
    const [contactData, setContactData] = useState(contactFeild);
    const [waitListData, setWaitListData] = useState(waitListField);
    const [serviceType, setServiceType] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [onSubmitData, setOnSubmitData] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    // const [isSubmitted, setIsSubmitted] = useState(false);

    const [timeScheduleArr, setTimeScheduleArr] = useState([
        {
            serHead: "Timing",
            serTimeArr: [],
        },
    ]);
    const servDate = serviceType?.date;
    let formattedDate;
    if (servDate) {
        const date = new Date(servDate);
        if (!isNaN(date.getTime())) {
            formattedDate = format(date, "dd-MM-yyyy");
            console.log("Formatted_date:", formattedDate);
        } else {
            console.error("Invalid date format:", servDate);
        }
    } else {
        console.error("Service date is undefined");
    }

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegex = /^\d{10}$/;
    const location = useLocation();
    const navigate = useNavigate();
    const colordata = useContext(Colorcontext);

    useEffect(() => {
        fetchCountry();
    }, []);
    const formData = JSON.parse(localStorage.getItem('formData'));
    useEffect(() => {
        console.log("Locatiomfdffdfdfd", location?.state);
        console.log("vvkkss112233", formData)

        setServiceType(location?.state);
        colordata.setcolor(true);
        return () => {
            colordata.setcolor(false);
        };
    });

    const handleChange = (e) => {
        // if(e?.target?.name === 'note'){
        // const noteVal = e.target.name === "note";
        // const newValue = e.target.value.replace(/\s/g, '_');
        // setContactData({ ...contactData, [e.target.name]: newValue });
        // } else {
        setContactData({ ...contactData, [e.target.name]: e.target.value });
        // }
    };

    const handleWaitListChange = (e) => {
        if (e?.target?.name === 'note') {
            const newValue = e.target.value.replace(/\s/g, '_');
            setWaitListData({ ...waitListData, [e.target.name]: newValue });
        } else {
            setWaitListData({ ...waitListData, [e.target.name]: e.target.value });
        }
    };

    const fetchTime = async () => {
        try {
            const response = await HomeServices.getTime();
            // Call your API function
            if (response && response?.status) {
                const timeSlots = response.data;
                setTimeScheduleArr([
                    {
                        serHead: "Timing",
                        serTimeArr: timeSlots,
                    },
                ]);
                console.log("getTimeResponse", response?.data);
            }
        } catch (error) {
            console.error("Error fetching warehouse data:", error);
        }
    };
    useEffect(() => {
        fetchTime();
    }, []);

    const fetchCountry = async () => {
        const res = await HomeServices.viewCountry();
        setCountryData(res?.data);
    };


    const onSubmit = async () => {
        // e.preventDefault();
        const {
            mobileNumber,
            email,
            firstName,
            lastName,
            note,
            countryName,
            countryCode,
        } = contactData;
        const isValid =
            mobileNumber?.trim() !== "" &&
            email?.trim() !== "" &&
            firstName?.trim() !== "" &&
            lastName?.trim() !== "" &&
            note?.trim() !== "" &&
            countryName?.trim() !== "";
        const mobileNumberAsNumber = parseInt(contactData.mobileNumber, 10);

        if (isValid) {
            if (!email.match(mailFormat)) {
                toast.error("Please Enter Valid Email..!");
            } else if (!phoneRegex.test(mobileNumber)) {
                toast.error("Please Enter Valid Mobile Number..!");

            } else {
                let data = {
                    price: `$${serviceType?.value?.price}`,
                    name: "Booking_Appointment",
                    booking_serviceType_id: location?.state?.value?._id,
                    date: moment(location?.state?.date).format("YYYY-MM-DD"),
                    timing_id: location?.state?.time,
                    mobileNumber: contactData?.mobileNumber,
                    email: contactData?.email,
                    firstName: contactData?.firstName,
                    lastName: contactData?.lastName,
                    note: contactData?.note?.trim()?.split(" ")?.join("_"),
                    country: contactData?.countryName,
                    mobileCode: contactData?.countryCode,
                    isUrgent: location?.state?.value?.isUrgent
                };

                //   try {
                //     const res = await HomeServices.initialBookingAppointment(data);
                //     // alert('Hello');
                //     console.log("ndmbjh765476ndb7888", res?.data);
                //   } catch (error) {
                //     // Handle any errors that occur during the execution of the promise
                //     console.error("Error during initial booking appointment:", error);
                //     // Optionally, you can show a user-friendly error message
                //     toast.error("An error occurred during submission. Please try again.");
                //   }
                // const res = await HomeServices?.initialBookingAppointment(data);
                // console.log("gfgfgffgfg", res);
                setOnSubmitData(data);
                navigate("/urgent-terms-and-condition", { state: data });
                // console.log("ndmbjh765476ndb7888", data);


                // setIsSubmitted(true);

            }
        } else {
            toast.error("Please Fill All the Inputs..!");
        }

        // if(onSubmitData){
        // }
    };

    // const onInitialSubmit = async () => {
    //   // e.preventDefault();
    //   const {
    //     mobileNumber,
    //     email,
    //     firstName,
    //     lastName,
    //     note,
    //     countryName,
    //     countryCode,
    //   } = contactData;
    //   const isValid =
    //     mobileNumber?.trim() !== "" &&
    //     email?.trim() !== "" &&
    //     firstName?.trim() !== "" &&
    //     lastName?.trim() !== "" &&
    //     note?.trim() !== "" &&
    //     countryName?.trim() !== "";
    //   const mobileNumberAsNumber = parseInt(contactData.mobileNumber, 10);

    //   if (isValid) {
    //     if (!email.match(mailFormat)) {
    //       toast.error("Please Enter Valid Email..!");
    //     } else if (!phoneRegex.test(mobileNumber)) {
    //       toast.error("Please Enter Valid Mobile Number..!");
    //     } else {
    //       let data = {
    //         price: `$${serviceType?.value?.price}`,
    //         name: "Booking_Appointment",
    //         booking_serviceType_id: location?.state?.value?._id,
    //         date: moment(location?.state?.date).format("YYYY-MM-DD"),
    //         timing_id: location?.state?.time,
    //         mobileNumber: contactData?.mobileNumber,
    //         email: contactData?.email,
    //         firstName: contactData?.firstName,
    //         lastName: contactData?.lastName,
    //         note: contactData?.note?.trim()?.split(" ")?.join("_"),
    //         country: contactData?.countryName,
    //         mobileCode: contactData?.countryCode,
    //       };
    //       const res = await HomeServices.initialBookingAppointment(data)
    //       console.log("ndmbjh765476ndb7888", res?.data); 
    //     }
    //   } else {
    //     toast.error("Please Fill All the Inputs..!");
    //   }
    // };

    useEffect(() => {
        const qr = searchParams.get("payment");
        console.log("sbfyuj65", qr);
        if (qr && qr == "true") {
            fetchModal();
        }
        console.log(qr, typeof qr, "qr");
    }, []);

    const fetchModal = () => {
        Swal.fire({
            title: "Payment Successull",
            icon: "success",
            confirmButtonColor: "#3085d6",
        }).then(() => {
            navigate("/");
        });
    };

    const submitWaitList = async () => {
        const {
            mobileNumber,
            email,
            firstName,
            lastName,
            note,
            countryName,
            countryCode,
        } = contactData;
        const { startDate, endDate, timeId } = waitListData;
        const isValid =
            mobileNumber?.trim() !== "" &&
            email?.trim() !== "" &&
            firstName?.trim() !== "" &&
            lastName?.trim() !== "" &&
            note?.trim() !== "" &&
            countryName?.trim() !== "" &&
            countryCode?.trim() !== "";
        const isNewValid =
            startDate?.trim() !== "" &&
            endDate?.trim() !== "" &&
            timeId.trim() !== "";
        if (isValid && isNewValid) {
            toast.success("success");
            let data = {
                booking_serviceType_id: location?.state?.value?._id,
                startDate: waitListData?.startDate,
                endDate: waitListData?.endDate,
                timing_id: waitListData?.timeId,
                mobileNumber: contactData?.mobileNumber,
                email: contactData?.email,
                firstName: contactData?.firstName,
                lastName: contactData?.lastName,
                note: encodeURIComponent(contactData?.note),
                // note: contactData?.note?.trim()?.split(" ")?.join("_"),
                country: contactData?.countryName,
                mobileCode: contactData?.countryCode,
            };
            const res = await HomeServices.addWaitList(data);
            if (res && res?.status) {
                // console.log("waitlist9875",res)
                setContactData(contactFeild);
                setWaitListData(waitListField);
            }
        } else {
            toast.error("Please Fill All The Fields");
        }
    };

    const handleTimeSelection = (selectedTime) => {
        if (selectedTime && typeof selectedTime.time === "string") {
            setWaitListData({
                ...waitListData,
                timeId: selectedTime._id,
            });
        } else {
            console.error("Invalid time format:", selectedTime);
        }
    };


    return (
        <section className="joinWaitListSection">
            <div className="cust_container">
                <BookAppImmi />
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-12">
                        {location?.state?.time === "" && (
                            <div>
                                <div className="joinWaitDiv">
                                    <p className="joinWaitHead">Join waitlist</p>
                                    <p className="weLLText">
                                        We'll let you know if a spot opens up!
                                    </p>
                                </div>
                                <div className="avaiPrefMainDiv">
                                    <div className="avaiPrefTextDiv">
                                        <p className="avaiPrefText">Availability preference</p>
                                    </div>
                                    <div className="selectDtDiv">
                                        <p className="dtTiSelecText">
                                            <span>
                                                <i class="fa-solid fa-calendar-week"></i>
                                            </span>
                                            Select date and time
                                        </p>
                                        <div className="dtDateMainDiv">
                                            <p className="dtDateHead">Date</p>
                                            <div className="dateListDiv">
                                                <Tabs>
                                                    <TabList>
                                                        {/* <Tab>Any Date</Tab> */}
                                                        <Tab>Select Date Range</Tab>
                                                    </TabList>

                                                    {/* <TabPanel></TabPanel> */}
                                                    <TabPanel>
                                                        <div className="tabRngeDiv">
                                                            <input
                                                                type="date"
                                                                name="startDate"
                                                                value={waitListData?.startDate}
                                                                onChange={handleWaitListChange}
                                                                className="tabRangeInp"
                                                            />
                                                            <span>-</span>
                                                            <input
                                                                type="date"
                                                                name="endDate"
                                                                value={waitListData?.endDate}
                                                                onChange={handleWaitListChange}
                                                                className="tabRangeInp"
                                                            />
                                                        </div>
                                                    </TabPanel>
                                                </Tabs>
                                            </div>
                                        </div>

                                        {timeScheduleArr.map((value, index, arr) => {
                                            return (
                                                <div
                                                    style={{
                                                        marginBottom: `${index === arr.length - 1 ? 0 : 18
                                                            }px`,
                                                    }}
                                                >
                                                    <p className="sevMorHead">{value.serHead}</p>
                                                    <div
                                                        className="sevTimeAmDiv"
                                                        style={{ marginBottom: "20px", cursor: "pointer" }}
                                                        onClick={(e) => {
                                                            let serEle = e.target.closest(".sevTimeAmColor");
                                                            let serActiveEle = e.target.closest(
                                                                ".sevTimeAmSelectColor"
                                                            );
                                                            if (serEle !== null) {
                                                                serEle.classList.remove("sevTimeAmColor");
                                                                serEle.classList.add("sevTimeAmSelectColor");
                                                                let prevText = serEle.innerHTML;
                                                                serEle.innerHTML =
                                                                    '<i class="fa-solid fa-check"></i> ' +
                                                                    prevText;
                                                            }

                                                            if (serActiveEle !== null) {
                                                                serActiveEle.classList.remove(
                                                                    "sevTimeAmSelectColor"
                                                                );
                                                                serActiveEle.classList.add("sevTimeAmColor");
                                                                let prevText = serActiveEle.innerHTML;
                                                                let prevTextArr = prevText.split(" ");
                                                                serActiveEle.innerHTML =
                                                                    prevTextArr[prevTextArr.length - 1];
                                                            }
                                                        }}
                                                    >
                                                        {value.serTimeArr.length === 0 ? (
                                                            <div className="sevTimeAm">
                                                                <span>All Booked</span>
                                                            </div>
                                                        ) : (
                                                            value.serTimeArr.map((item) => {
                                                                console.log("navdefyea546", item);
                                                                return (
                                                                    <div
                                                                        className="sevTimeAm sevTimeAmColor"
                                                                        onClick={() => handleTimeSelection(item)}
                                                                    >
                                                                        <span>{item?.time}</span>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div>
                                        <button className="addPrefBtn">Add preference</button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="avaiPrefMainDiv">
                            <div className="avaiPrefTextDiv">
                                <p className="avaiPrefText">Services and staff</p>
                                <Link to="/bookappointment" className="aviEditLink">
                                    Edit
                                </Link>
                            </div>
                            <div className="phnAppsMainDiv">
                                <div className="phnApSpanInDiv">
                                    <span className="phnAppIcon">
                                        <i class="fa-regular fa-note-sticky"></i>
                                    </span>
                                    <div className="phnAppoTextsDiv">
                                        <p className="phnAppoText">
                                            {`${serviceType?.value?.name} ($${serviceType?.value?.price}) ${serviceType?.value?.isUrgent === true ? "( Urgent )" : ""}`}
                                        </p>
                                        <p className="phnAppoSecText">{`${serviceType?.value?.service_type} appointment`}</p>
                                        <p className="phnAppoSecText">{`(${formattedDate})`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="avaiPrefMainDiv">
                            {/* <HowcanMeetForm/> */}
                            <div className="avaiPrefTextDiv">
                                <p className="avaiPrefText">How can we contact you?</p>
                            </div>
                            <form action="" className="howCanWeForm">
                                <div className="howFormInpDiv">
                                    <select
                                        name=""
                                        id=""
                                        className="howFormHlfInp"
                                        style={{ color: "#FF9C00" }}
                                        onChange={(e) => {
                                            setContactData({
                                                ...contactData,
                                                countryName: e.target.value,
                                            });
                                        }}
                                    >
                                        <option value="">Select Country</option>
                                        {countryName?.map((val) => {
                                            return (
                                                <>
                                                    <option value={val?.name}>{val?.name}</option>
                                                </>
                                            );
                                        })}
                                    </select>
                                    <div className="howFormHlfInpInDiv howFormHlfInpMarge">
                                        <select
                                            name=""
                                            id=""
                                            className="howFormHlfInp"
                                            style={{
                                                color: "#FF9C00",
                                                width: "100px",
                                                height: "42px",
                                                marginRight: "2px",
                                            }}
                                            onChange={(e) => {
                                                setContactData({
                                                    ...contactData,
                                                    countryCode: e.target.value,
                                                });
                                            }}
                                        >
                                            <option value="+1">+1</option>
                                            {countryCode?.map((val) => {
                                                return (
                                                    <>
                                                        <option value={val?.dial_code}>
                                                            {val?.dial_code}
                                                        </option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                        <input
                                            type="text"
                                            name="mobileNumber"
                                            value={contactData?.mobileNumber}
                                            onChange={handleChange}
                                            placeholder="Mobile Phone"
                                            className="howFormInInp"
                                        />
                                    </div>
                                </div>

                                <div className="howFormInpDiv">
                                    <input
                                        type="text"
                                        name="email"
                                        onChange={handleChange}
                                        value={contactData?.email}
                                        placeholder="Email"
                                        className="howFormFullInp"
                                    />
                                </div>
                                <div className="howFormInpDiv">
                                    <input
                                        type="text"
                                        onChange={handleChange}
                                        name="firstName"
                                        value={contactData?.firstName}
                                        placeholder="First name"
                                        className="howFormHlfInp"
                                    />
                                    <input
                                        type="text"
                                        placeholder="Last name"
                                        className="howFormHlfInp howFormHlfInpMarge"
                                        onChange={handleChange}
                                        name="lastName"
                                        value={contactData?.lastName}
                                    />
                                </div>
                                <div>
                                    <textarea
                                        onChange={handleChange}
                                        name="note"
                                        value={contactData?.note}
                                        id=""
                                        cols=""
                                        rows="5"
                                        placeholder="Please Share anything that will help prepare for our meeting."
                                        className="howFormFullInp"
                                    ></textarea>
                                </div>
                            </form>
                        </div>

                        <div>
                            {location?.state?.time === "" ? (
                                <button className="joinWtListBtn" onClick={submitWaitList}>
                                    Join waitlist
                                </button>
                            ) : (
                                <button
                                    className="joinWtListBtn"
                                    onClick={() => {
                                        onSubmit();
                                    }}
                                >
                                    Submit
                                </button>
                            )}
                            <p className="upnText">
                                By joining the waitlist, you agree to receive automated
                                transactional text messages from this merchant. In the event an
                                appointment is booked, you also agree to the merchant’s{" "}
                                <Link to="/cancel-policy">cancellation policy</Link> . Contact the merchant
                                to learn more about privacy practices.
                            </p>
                        </div>
                    </div>
                </div>
                <BackPageBtn backLink="/preferdateTime" />
            </div>
        </section>
    )
}

export default UrgentJoinList