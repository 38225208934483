/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { Link } from 'react-router-dom'

const Address = () => {
    return (
        <>
            <section className=''>
                <div className='cust_container'>
                    <div className='row'>
                        <div className='col-lg-6 col-xl-6 col-md-12 col-12'>
                            <div className='contctbrdrdiv'>
                                <div className=''>
                                    <p className='cntntaddpp'>Address- 187 Borden Avenue N, Kitchener </p>
                                </div>
                                <div className='cntntemldiv'>
                                    <div className='cntnstenvlp'>
                                        <i className="fa-solid fa-envelope-circle-check"></i>
                                    </div>
                                    <div>
                                        <p>Email:</p>
                                        <Link to="/" className='emllgh'>Support@deshvidesh.ca</Link>
                                    </div>
                                </div>
                                <div className='cntntemldiv'>
                                    <div className='cntnstenvlp'>
                                        <i className="fa-solid fa-phone-volume"></i>
                                    </div>
                                    <div>
                                        <p>Ph No. :</p>
                                        <p className='phdivtxt'> +1-5489949465 </p>
                                    </div>
                                </div>
                                <div className='cntntemldiv'>
                                    <div className='cntnstenvlp'>
                                        <i className="fa-solid fa-phone-volume"></i>
                                    </div>
                                    <div>
                                        <p>Ph No. :</p>
                                        <p className='phdivtxt'> +1 (519) 998-1002</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-xl-6 col-md-12 col-12'>
                            <div className='mapgghh'>
                                {/* <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14728.727405628732!2d88.33364660000001!3d22.64700745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1700658432319!5m2!1sen!2sin"
                                    width={600}
                                    height={250}
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                /> */}
                               
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d46317.477145084085!2d-80.5090207!3d43.4847609!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48ac7d2867904033%3A0x8331a10c49cde0e5!2sDesh%20Videsh%20Immigration!5e0!3m2!1sen!2sin!4v1704462034877!5m2!1sen!2sin"
                                    width={600}
                                    height={220}
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Address
